var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ul", { staticClass: "nav", attrs: { id: "side-menu" } }, [
    !_vm.isGrantedBookKeeper
      ? _c("li", [
          _c("button", [_vm._v("Tagesgeschäft")]),
          _vm._v(" "),
          _vm._m(0),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.isGrantedBookKeeper
      ? _c("li", [_c("button", [_vm._v("Vorgänge")]), _vm._v(" "), _vm._m(1)])
      : _vm._e(),
    _vm._v(" "),
    _vm._m(2),
    _vm._v(" "),
    !_vm.isGrantedBookKeeper
      ? _c("li", [_c("button", [_vm._v("Stammdaten")]), _vm._v(" "), _vm._m(3)])
      : _vm._e(),
    _vm._v(" "),
    !_vm.isGrantedBookKeeper
      ? _c("li", [
          _c("button", [_vm._v("Destinationsverwaltung")]),
          _vm._v(" "),
          _vm._m(4),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.isGrantedBookKeeper
      ? _c("li", [
          _c("button", [_vm._v("Reisekonzepte")]),
          _vm._v(" "),
          _vm._m(5),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.isGrantedBookKeeper
      ? _c("li", [_c("button", [_vm._v("Verwaltung")]), _vm._v(" "), _vm._m(6)])
      : _vm._e(),
    _vm._v(" "),
    !_vm.isGrantedBookKeeper
      ? _c("li", [
          _c("button", [_vm._v("Kalkulationspreise")]),
          _vm._v(" "),
          _vm._m(7),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.isGrantedBookKeeper
      ? _c("li", [
          _c("a", { attrs: { href: "/data-exports" } }, [
            _vm._v("Daten-Export"),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.isGrantedBookKeeper
      ? _c("li", [_c("a", { attrs: { href: "/faqs" } }, [_vm._v("FAQs")])])
      : _vm._e(),
    _vm._v(" "),
    _vm.isGrantedAdmin || _vm.isGrantedBookKeeperAdmin
      ? _c("li", [
          _c("button", [_vm._v("Admin")]),
          _vm._v(" "),
          _c(
            "ul",
            [
              _vm.isGrantedAdmin
                ? [_vm._m(8), _vm._v(" "), _vm._m(9)]
                : _vm._e(),
              _vm._v(" "),
              _vm._m(10),
              _vm._v(" "),
              _vm.isGrantedAdmin
                ? [_vm._m(11), _vm._v(" "), _vm._m(12), _vm._v(" "), _vm._m(13)]
                : _vm._e(),
            ],
            2
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("li", [
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.handleLogout.apply(null, arguments)
            },
          },
        },
        [_vm._v("Logout")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _c("a", { attrs: { href: "/user-dashboard" } }, [_vm._v("Dashboard")]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "/sales-dashboard" } }, [
          _vm._v("Sales Dashboard"),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [_c("a", { attrs: { href: "/emails" } }, [_vm._v("Mailbox")])]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "/emails/archive" } }, [
          _vm._v("Emails Archiv"),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "/documents/booking-plan" } }, [
          _vm._v("Belegungsplan"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _c("a", { attrs: { href: "/orders?sort=offer_sent" } }, [
          _vm._v("Angebote"),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "/orders?sort=offer_accepted" } }, [
          _vm._v("Buchungen"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("button", [_vm._v("Buchführung")]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _c("a", { attrs: { href: "/client-invoices" } }, [
            _vm._v("Kundenrechnungen "),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "/invoices" } }, [
            _vm._v("Leistungsrechnungen "),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "/control" } }, [
            _vm._v("Abschlusskontrolle "),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [_c("a", { attrs: { href: "/clients" } }, [_vm._v("Kunden")])]),
      _vm._v(" "),
      _c("li", [_c("a", { attrs: { href: "/hotels" } }, [_vm._v("Hotels")])]),
      _vm._v(" "),
      _c("li", [_c("a", { attrs: { href: "/ferries" } }, [_vm._v("Fähren")])]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "/airlines" } }, [_vm._v("Airlines")]),
      ]),
      _vm._v(" "),
      _c("li", [_c("a", { attrs: { href: "/trains" } }, [_vm._v("Züge")])]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "/others?type=restaurant" } }, [
          _vm._v("Restaurants"),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "/others" } }, [_vm._v("Leistungsträger")]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "/other_service_types" } }, [
          _vm._v("Zusatzleistungen"),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "/agencies" } }, [_vm._v("Agenturen")]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "/others?type=financial" } }, [
          _vm._v("Sonstiges"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _c("a", { attrs: { href: "/destinations" } }, [
          _vm._v("Destinationen"),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [_c("a", { attrs: { href: "/areas" } }, [_vm._v("Regionen")])]),
      _vm._v(" "),
      _c("li", [_c("a", { attrs: { href: "/places" } }, [_vm._v("Orte")])]),
      _vm._v(" "),
      _c("li", [_c("a", { attrs: { href: "/ports" } }, [_vm._v("Häfen")])]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "/airports" } }, [_vm._v("Flughäfen")]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "/train_stations" } }, [_vm._v("Bahnhöfe")]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "/location-charges" } }, [_vm._v("Gebühren")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _c("a", { attrs: { href: "/concepts/orders" } }, [
          _vm._v("Gesamtkonzepte"),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "/concepts/days" } }, [
          _vm._v("Tageskonzepte"),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "/routes" } }, [_vm._v("Routenstichpunkte")]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "/free_texts" } }, [_vm._v("Freitexte")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _c("a", { attrs: { href: "/emails/templates" } }, [
          _vm._v("Emailvorlagen"),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "/templates/offer-intro" } }, [
          _vm._v("Vorlagen für Anschreiben"),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "/templates/trip-details" } }, [
          _vm._v("Vorlagen für Reiseunterlagen"),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "/templates/offer-bullets" } }, [
          _vm._v("Vorlagen für Angebotsinfos"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _c("a", { attrs: { href: "/calculation-prices/hotels" } }, [
          _vm._v("Hotels"),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "/calculation-prices/ferries" } }, [
          _vm._v("Fähren"),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "/calculation-prices/trains" } }, [
          _vm._v("Züge"),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "/calculation-prices/others" } }, [
          _vm._v("Zusatzleistungen"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("a", { attrs: { href: "/finances" } }, [_vm._v("Finanzen")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("a", { attrs: { href: "/admin/currencies" } }, [
        _vm._v("Wechselkurse"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("a", { attrs: { href: "/statistics" } }, [_vm._v("Statistiken")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("a", { attrs: { href: "/organisations" } }, [_vm._v("Organisation")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("a", { attrs: { href: "/banks" } }, [_vm._v("Banken")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("a", { attrs: { href: "/admin/settings" } }, [
        _vm._v("Einstellungen"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }