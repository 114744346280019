<template>

        <component :value="date" :placeholder="placeholder"
                   :inputClass="inputClass" :alignment="alignment"
                   :is="wrap ? 'WrapperInput' : 'SingleInput'"
                   ref="datePicker"
        >
            <slot></slot>
        </component>

</template>

<script>
import moment from 'moment';
import Datepicker from 'vue-bulma-datepicker';
import { German } from 'flatpickr/dist/l10n/de.js';
import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr';


export default {
    name: 'vue-bulma-datepicker-locale-de',


    extends: Datepicker,


    props: {
        config: {
            type: Object,
            default: () => ({
                locale: German,
                // Because of the leap year issue with use the default format
                // and then transform it to ours
                parseDate:  function (dateStr) { return moment(dateStr, 'DD.MM.YYYY').toDate(); },
                formatDate: function (dateObj) { return moment(dateObj).format('DD.MM.YYYY'); },
                dateFormat: 'd.m.Y',
                allowInput: true,
                altFormat:'d.m.Y',
                disableMobile: true
            })
        },


        placeholder: {
            type: String,
            default: 'Datum'
        },
    },


    created: function () {
        const self = this;

        this.config.plugins = [
            ShortcutButtonsPlugin({
                button: {
                    label: 'Datum entfernen',
                },
                onClick: (index, fp) => {
                    fp.clear();
                    fp.close();
                    self.$emit('input', null)
                }
            })
        ];


        this.config.onChange = function (selectedDates, dateStr) {

            dateStr = dateStr === '' ? null : dateStr;
            self.$emit('change', dateStr);
        }

        this.config.onClose = function (dates, dateStr, picker) {
            if(picker.altInput){
                picker.setDate(picker.altInput.value, true, picker.config.altFormat)
            }
        }
    },


    methods: {
        open: function () {
            this.datepicker.open();
        },

        select: function () {
            this.$refs.datePicker.$el.nextSibling.select();
        },



        clearDate: function () {
            this.selectedDates = null;
            this.datepicker.clear();
            this.datepicker.close();
            this.$emit('input', null);
        },
    },




    watch: {
        value: function (newValue, oldValue) {
            if (newValue === null) {
                this.clearDate()
            }
        }
    }
}
</script>
