<template>

    <div class="columns has-margin-top">
        <div class="column">
            <div class="bp-panel has-header is-grey-lightest is-settings">
                <div class="panel-header is-grey-lightest">
                    <h1>Kalkulationseinstellungen</h1>
                </div>
                <div class="panel-content">
                    <div class="columns">
                        <div class="column is-two-thirds">
                            <div class="level">
                                <label class="label">Kundenwährung:</label>
                            </div>
                        </div>
                        <div class="column is-one-third">
                            <multiselect
                                class="level-item is-blue"
                                v-model="currencyForm.orderCurrency"
                                :options="options.clientCurrencies"
                                :allow-empty="false"
                                placeholder=""
                                v-on:input="updateOrderCurrency"
                            />
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-two-thirds">
                            <div class="level">
                                <label class="label">Währung zum Kalkulieren:</label>
                            </div>
                        </div>
                        <div class="column is-one-third">
                            <multiselect
                                class="level-item"
                                v-model="currencyForm.calculationCurrency"
                                :options="options.calculationCurrencies"
                                :allow-empty="false"
                                placeholder=""
                                v-on:input="updateCalculationCurrency"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="column">
            <div class="bp-panel has-header is-grey-lightest is-settings">
                <div class="panel-header is-grey-lightest">
                    <h1>Preisvorschläge aus den Stammdaten</h1>
                </div>
                <div class="panel-content">
                    <div class="columns">
                        <div class="column is-half">
                            <div class="level">
                                <label class="label">Preissuche bis Jahr:</label>
                            </div>
                        </div>
                        <div class="column is-half">
                            <multiselect
                                v-model="form.year"
                                :custom-label="item => item"
                                v-bind:options="options.years"
                                v-on:select="handleSearch"
                            >
                            </multiselect>
                        </div>
                    </div>
<!--                    <div class="columns">
                        <div class="column is-half">
                            <div class="level">
                                <label class="label">Preise zurücksetzen :</label>
                            </div>
                        </div>
                        <div class="column is-half">
                            <button class="button" v-on:click.prevent.stop="resetCalculation">Zurücksetzen</button>
                        </div>
                    </div>-->
                    <div class="columns">
                        <div class="column is-half">
                            <div class="level">
                                <label class="label">Verlinkte Preise :</label>
                            </div>
                        </div>
                        <div class="column is-half">
                            <button class="button" v-on:click.prevent.stop="resetCalculationRequestPrices">zurücksetzen</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="column">
            <div class="bp-panel has-header is-grey-lightest is-settings">
                <div class="panel-header is-grey-lightest">
                    <h1>Anzeigeoptionen</h1>
                </div>
                <div class="panel-content">
                    <div class="columns">
                        <div class="column">
                            <toggle-switch
                                v-model="showExchangeRates"
                                v-on:input="updateShowExchangeRates"
                                btn-class="is-fullwidth"
                            >
                                <option :value="false">Preisvorschläge</option>
                                <option :value="true">Umrechnungen</option>
                            </toggle-switch>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="column">
            <div class="bp-panel has-header is-grey-lightest is-settings" v-if="Object.keys(defaultExchangeRates).length > 1 || currencyForm.orderCurrency !== currencyForm.calculationCurrency">
                <div class="panel-header is-grey-lightest">
                    <h1>Währungskurse</h1>
                </div>
                <div class="panel-content">
                    <template v-for="(rate, currency) in defaultExchangeRates">
                        <div class="columns is-variable is-2" v-if="currency !== currencyForm.calculationCurrency">
                            <div class="column is-5">
                                <div class="level">
                                    <label class="label">1 {{ratesAreReversed ? currencyPreference : currency}} entspricht:</label>
                                </div>
                            </div>
                            <div class="column is-7">
                                <input-price
                                    v-bind:value="ratesAreReversed && exchangeRates[currency] > 0 ? 1/exchangeRates[currency] : exchangeRates[currency]"
                                    class="is-medium-small level-item"
                                    placeholder="0,00"
                                    digits="4"
                                    :defaultExchangeRate="ratesAreReversed && rate > 0 ? 1/rate : rate"
                                    :force-show-exchange-rates="true"
                                    v-on:input="value => updateExchangeRate(ratesAreReversed && value > 0 ? 1/value : value, currency)"
                                    :suffix="ratesAreReversed ? currency : currencyForm.calculationCurrency"
                                />
                            </div>
                        </div>
                    </template>
                    <div class="columns is-variable is-2" v-if="currencyForm.orderCurrency !== currencyForm.calculationCurrency && !hasOverlappingCurrencies">
                        <div class="column is-5">
                            <div class="level">
                                <label class="label">1 {{currencyForm.calculationCurrency}} entspricht:</label>
                            </div>
                        </div>
                        <div class="column is-7">
                            <input-price
                                v-model="calculationToClientExchangeRate"
                                class="is-medium-small level-item"
                                placeholder="0,00"
                                digits="4"
                                :defaultExchangeRate="defaultCalculationToOrderExchangeRate"
                                :force-show-exchange-rates="true"
                                :suffix="currencyForm.orderCurrency"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@calculations/store';
import { Multiselect, ToggleSwitch } from '@components/form'
import InputPrice from '@calculations/components/InputPrice'
import {notifyError, notifySuccess} from "@components/Notification";
import {edit, getAxios} from "@api";


export default {
    store,

    components: {
        Multiselect,
        ToggleSwitch,
        InputPrice
    },

    data: function () {
        return {
            showExchangeRates: false,
            currencyForm: {
                orderCurrency: this.$store.state.order.orderCurrency,
                calculationCurrency: this.$store.state.order.calculationCurrency,
            },
            form: {
                years: [],
                year: '2020',
                _groups: ['calculation:providerPrices']
            },
        }
    },

    computed: {

        orderYear: function () {
            const startAt = this.$store.state.order.startAt;
            return !!startAt ? parseInt(startAt.substring(6, 10)) : null;
        },

        defaultExchangeRates: function() {
            return this.$store.state.defaultExchangeRates;
        },

        defaultCalculationToOrderExchangeRate: function () {
            return this.$store.state.defaultCalculationToOrderExchangeRate[this.currencyForm.calculationCurrency];
        },

        calculationToClientExchangeRate: {
            get: function () {
                let rate = this.$store.state.calculationToOrderExchangeRate;
                return rate ? rate[this.currencyForm.calculationCurrency] : 0;
            },

            set: function (value) {
                this.$store.state.calculationToOrderExchangeRate[this.currencyForm.calculationCurrency] = value;
                this.$store.commit('updateSavedExchangeRate', [this.currencyForm.calculationCurrency, this.currencyForm.orderCurrency, value]);
            }
        },

        exchangeRates: function () {
            return this.$store.state.exchangeRates;
        },

        providerPrices: {
            get: function () {
                return this.options.providerPrices
                    .find(option => option.value === this.form.providerPrices)
            },

            set: function (value) {
                this.form.providerPrices = !!value ? value.value : null
            }
        },

        oneCurrencyRate: function () {
            return this.$store.state.options.clientConfig.calculation.oneCurrencyRate;
        },

        currencyPreference: function () {
            return this.$store.state.options.clientConfig.calculation.currencyPreference;
        },

        ratesAreReversed: function () {
            return this.currencyPreference === this.currencyForm.calculationCurrency;
        },

        hasOverlappingCurrencies: function () {
            return this.oneCurrencyRate && this.defaultExchangeRates[this.currencyPreference] && this.defaultExchangeRates[this.currencyPreference] !== 1;
        },


        options: function (){
            const year = this.orderYear
            return {
                clientCurrencies: this.$store.state.options.clientCurrencies,
                calculationCurrencies: this.$store.state.options.calculationCurrencies,


                years: [...Array(6).keys()]
                    .map(n => year - 5 + n),
                providerPrices: [
                    { value: false, label: 'Stammdaten der Leistungsträger einbeziehen' },
                    { value: true, label: 'nur in den Kalkulationspreisn' }
                ],
                potentialExchangeRates: Object.keys(this.$store.state.exchangeRates).filter(rate => rate !== this.currencyForm.orderCurrency),
            }
        },


    },

    watch: {
        orderYear: {
            immediate: true,
            handler: function (year) {
                if (!!year) {
                    this.form.year = year;
                }
            }
        },
    },

    methods: {
        updateExchangeRate: function (value, currency) {
            this.$store.state.exchangeRates[currency] = value;
            this.$store.commit('updateSavedExchangeRate', [currency, this.currencyForm.calculationCurrency, value]);
        },

        handleSearch: function (value) {

            let diff = this.orderYear - value + 1;

            this.form.years = [...Array(diff).keys()]
                .map(n => this.orderYear - n);

            this.$emit('submit', this.form )
        },

        enableLoaded: function () {
            this.$store.commit('updateCalculationSaved', true)
            //force change such that handler in inputPrice component is run
            this.$nextTick(() => {
                this.$store.commit('updateCalculationSaved', false)
            })
        },
        resetCalculation: function () {
            this.$store.commit('updateLoading', true);
            getAxios('orders/' + this.$store.state.order.id + '/reset').then(response => {
                if (response.data.calculation) {
                    this.$store.commit('updateCalculationID', response.data.calculation);
                    this.$store.dispatch('fetchPrices');
                    notifySuccess("Die Preise wurden zurückgesetzt");
                }
            }).catch(err => {
                notifyError(err);
            })
        },
        resetCalculationRequestPrices: function () {
            this.$store.commit('updateLoading', true);
            edit('orders/'+ this.$store.state.order.id + '/reset_calculation_request_prices', {}).then(response => {
                this.$store.dispatch('fetchPrices');
                notifySuccess("Die Preise wurden zurückgesetzt");
            }).catch(err => {
                notifyError(err);
            })
        },

        updateShowExchangeRates: function () {
            this.$store.commit('updateShowExchangeRates', this.showExchangeRates)
        },

        updateOrderCurrency: function () {
            this.$store.commit('updateOrderCurrency', this.currencyForm.orderCurrency)
            this.$store.dispatch('changeOrderCurrency')
        },

        updateCalculationCurrency: function () {
            //this.$store.commit('updateCalculationCurrency', this.currencyForm.calculationCurrency)

            this.$store.dispatch('changeCalculationCurrency', this.currencyForm.calculationCurrency)
        }

    }
}
</script>
